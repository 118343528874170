const zh = () => {
  return {
    common: {
      hello: "你好",
      none: "無",
      cancel: "取消",
      logout: "登出",
      confirm: "確定",
      ok: "確定",
      inventory: "物件",
      character: "角色",
      system: "系統",
      hint: "提示",
      askAnswer: "我放棄了，告訴我答案吧！",
      wrongAnswer: "答案錯誤",
      leftAttemps: " 次機會",
      continue: "繼續",
      submit: "送出",
      loggingOut: "正在登出",
      skip: "跳過",
      login: "登入",
      register: "註冊",
      lastUpdate: "最後更新時間",
    },
    login: {
      login: "Playreal ID 登入/註冊",
      login2: "登入/註冊",
      login3: "登入 Playreal ID",
      enterSerial: "請輸入序號",
      goRegister: "前往註冊新帳號",
      forgetPassword: "忘記密碼",
      iAgree: "我同意",
      userAgreement: "Playreal 使用者條款",
      ifYouHaveAnAccount:
        "如果你之前已經註冊過了，請點擊「取消」並輸入正確的帳號密碼登入Playreal系統。",
      userName: "使用者名稱",
      passwordConfirm: "驗證密碼",
      password: "密碼",
      email: "帳號（Email）",
      continue: "沒有看到了，讓我繼續",
      dontShow: "下次不要再出現此警告",
      emailEmptyWarning: "帳號不可為空白",
      emailFormatWarning: "帳號格式不正確",
      passwordWarning: "密碼不可為空白",
      passwordLengthWarning: "密碼長度需大於6個字元",
      passwordConfirmWarning: "密碼與確認密碼不相符",
      userAgreementWarning: "未勾選使用者條款",
      longerThanExpected: "已超過自動登入時效，請回到登入頁登入",
      error: "發生錯誤，請稍後再試",
      enterCorrectSerial: "請輸入正確的序號",
      anonymousLogin: "匿名登入",
      create: "創建一個新隊伍",
      createText1: "你將創立一個新隊伍，並成為這個隊伍的",
      createText2: "，讚啦！",
      tip: "如果你的隊友已經創建了隊伍，請直接掃描他手機/裝置上的QRCode即可加入到他的隊伍當中。",
      tip2: "你可以加入別人的隊伍，或是自己創建一個隊伍。 ",
      tip3: " 一個隊伍只需要一個人創建隊伍即可。",
      tip4: "請出示此QRCode給你的隊友，掃描加入你的隊伍！",
      wait: "等等...",
      ok: "是的",
      hi: "嗨,",
      exit: "退出這個隊伍",
      createTeam: "創建隊伍",
      enterOrScan: "輸入或掃描隊伍序號",
    },
    inventory: {
      enterPassword: "請輸入密碼",
      wrongAnswer: "答案錯誤",
      keyword: "輸入關鍵字",
    },
    system: {
      currentProgress: "目前進度",
      currentDuration: "目前歷時",
      currentAccount: "登入帳號",
      teamNumber: "隊伍編號",
      qrcodeTips: "如果有隊員關閉視窗，請重新掃描此QRCode",
      serialNumber: "登錄序號",
      browser: "瀏覽器版本",
      exit: "離開隊伍",
      teamManage: "隊伍管理",
      addTeammate: "加入新隊員",
    },
    quiz: {
      enterAnswer: "請輸入您的答案",
      expand: "展開以輸入答案",
      collapse: "收回",
    },
    character: {
      exclusiveItems: "角色專屬物件",
    },
    loading: {
      gameLoading: "遊戲載入中...",
      continue: "繼續遊戲",
      start: "開始遊戲",
      pleaseSelect: "請問你想要...",
      restart: "重新開始",
      continue2: "從上次進度繼續",
      confirmSave: "確認存檔中...",
      loadComplete: "讀取完成",
      newGame: "新遊戲",
    },
    completing: {
      resetLocalSavefile: "確定重新開始嗎？",
      result: "遊戲成果",
      achievement: "您獲得的遊戲結局",
      duration: "遊戲總費時",
      replay: "重新遊玩",
      checkResult: "重看遊戲成果",
      scores: "分數",
      retest: "重新測試",
    },
    other: {
      sychronizing: "進度同步中",
      waiting: "等待所有隊員完成任務",
      continue: "強制繼續",
      update: "更新狀態",
      IArrive: "我到了",
    },
    advanceBranch: {
      noConsensus: "未達成共識",
      countDownToVote: "倒數5秒後重新進行選擇",
      countDownToResult: "倒數5秒後跳轉至投票結果",
      waiting: "請等待隊長做出選擇",
      voting: "投票統計中",
      finalChoice: "投票結果是",
    },
    team: {
      oops: "喔喔！",
      up: "加入隊伍",
      maxPlayer: "本隊伍最多人數為{{count}}人",
      mustChooseARole: "角色有*號的為必選角色",
      mustChooseARoleHint: "*為必選角色",
      name: "名稱",
      character: "角色",
      chooseRole: "選擇角色",
      clickRole: "點選角色",
      currentRole: "當前角色",
      roleChosen: "已選擇",
      myself: "我",
      reset: "重新組隊",
      confirm: "確認組隊",
      team: "隊伍",
      leave: "離開隊伍",
      confirming: "等待隊長確認組隊中...",
      currentTeamSize:
        "目前隊伍人數為{{count}}人，確定大家都到齊了嗎？（遊戲一旦開始後將無法加入新成員）",
      minTeamSize: "哦喔！你的隊伍人數不足！最少需要 {{count}} 人",
      maxTeamSize: "哦喔！你的隊伍人數超過上限 {{count}} 人",
      roleDuplicatable: "哦喔！你選擇的角色重複了！本遊戲不接受重複角色",
      missingRole:
        "哦喔！必選的角色沒有被選到！請確認每個必選角色都分配到至少一個玩家。",
      notSufficient: "未滿足創建隊伍條件",
      letScan: "請其他參與者掃描右方QRCode加入本隊伍",
      letScan2: "請出示給其他隊友，掃描左方的QRCode來加入你的隊伍。",
      leader: "隊長",
      member: "隊員",
      resetTeamConfirm: "確定要解散隊伍嗎？",
      exitTeamConfirm: "確定要離開隊伍嗎？",
      ready: "準備完成",
      waiting: "等待隊長啟動體驗...",
      start: "啟動體驗",
      noTeamName: "我的隊伍",
      serial: "隊伍序號",
      player: "玩家",
      noNamePlayer: "未命名玩家",
      allHere: "所有人都到了嗎？",
      confirmJoin:
        "請確認是否所有隊友都已加入到隊伍當中，目前隊伍中共有{{count}}個人（包括你）。",
      addTeammatesInstruction:
        "如果你想要等下再加入其他隊友，請在體驗過程中按下「設定（右上角齒輪） > 新增隊友」",
      wait: "等等...",
      startNew: "開始吧！",
      displayName: "顯示名稱",
      editName: {
        part1: "編輯 ",
        part2: " 名稱",
      },
      edit: {
        display: "顯示",
        team: "隊伍",
      },
    },
  };
};
export default zh;
