const en = () => {
  return {
    common: {
      hello: "Hello",
      none: "None",
      cancel: "Cancel",
      logout: "Logout",
      confirm: "Confirm",
      ok: "OK",
      inventory: "Items",
      character: "Character",
      system: "System",
      hint: "Hints",
      askAnswer: "I give up show me the answer.",
      wrongAnswer: "Wrong answer",
      leftAttemps: " chance(s)",
      continue: "Continue",
      submit: "Submit",
      loggingOut: "Logging Out",
      skip: "Skip",
      login: "Login",
      register: "Register",
      lastUpdate: "Last Update",
    },
    login: {
      login: "Playreal ID Login/Register",
      login2: "Login/Register",
      login3: "Login Playreal ID",
      enterSerial: "Enter Serial Number",
      goRegister: "Go register",
      forgetPassword: "Forget Password",
      iAgree: "I agree to the",
      userAgreement: "Playreal User Agreement",
      ifYouHaveAnAccount:
        "If you have previously registered, please click 'Cancel' and enter the correct username and password to login to the Playreal system.",
      userName: "Username",
      passwordConfirm: "Confirm Password",
      password: "Password",
      email: "Account (Email)",
      continue: "I understand, continue",
      dontShow: "Don't show this warning again",
      emailEmptyWarning: "Account cannot be blank",
      emailFormatWarning: "Account format is incorrect",
      passwordWarning: "Password cannot be blank",
      passwordLengthWarning: "Password must be at least 6 characters long",
      passwordConfirmWarning: "Password and confirm password do not match",
      userAgreementWarning: "User agreement must be accepted",
      longerThanExpected:
        "Automatic login has expired, please return to the login page to login",
      error: "An error occurred, please try again later",
      enterCorrectSerial: "Please enter the correct serial number",
      anonymousLogin: "Anonymous Login",
      create: "Create a new team",
      createText1: "You will create a new team and become the",
      createText2: "of this team. Awesome!",
      tip: "If your teammate has already created a team, you can join their team by scanning the QR code on their phone/device.",
      tip2: "You can join someone else's team, or create your own team.",
      tip3: "Only one person needs to create a team.",
      tip4: "Please show this QR code to your teammates and scan to join your team!",
      wait: "Please wait...",
      ok: "OK",
      hi: "Hi,",
      exit: "Exit this team",
      createTeam: "Create Team",
      enterOrScan: "Enter or scan serial number",
    },
    inventory: {
      enterPassword: "Enter password",
      wrongAnswer: "Wrong Answer",
      keyword: "Enter keywords",
    },
    system: {
      currentProgress: "Progress",
      currentDuration: "Play time",
      currentAccount: "Account",
      teamNumber: "Team Number",
      qrcodeTips:
        "If a team member closes the window, please rescan this QR code",
      serialNumber: "Key Number",
      browser: "Browser version",
      exit: "Leave the team",
      teamManage: "Team members",
      addTeammate: "Add Teammates",
    },
    quiz: {
      enterAnswer: "Enter your answer",
      expand: "Expand to enter answer",
      collapse: "Collapse",
    },
    character: {
      exclusiveItems: "Character-specific items",
    },
    loading: {
      gameLoading: "Loading...",
      continue: "Continue game",
      start: "Start",
      pleaseSelect: "Please select...",
      restart: "Restart",
      continue2: "Continue from last save",
      confirmSave: "Loading…",
      loadComplete: "Load complete",
      newGame: "New game",
    },
    completing: {
      resetLocalSavefile: "Are you sure you want to start over?",
      result: "Game Result",
      achievement: "The ending you got",
      duration: "Game total time",
      replay: "Replay Game",
      checkResult: "Review your result",
      scores: "Score",
      retest: "Retest this game",
    },
    other: {
      sychronizing: "Syncing up…",
      waiting: "Wait for all players to finish the task.",
      continue: "Force Syncing",
      update: "Update Status",
      IArrive: "I'm here",
    },
    advanceBranch: {
      noConsensus: "No consensus was reached",
      countDownToVote: "Redirecting to re-vote in 5 seconds",
      countDownToResult:
        "Redirecting to the corresponding voting results in 5 seconds",
      waiting: "Wait for the leader making a choice",
      voting: "Voting in progress",
      finalChoice: "Voting result is...",
    },
    team: {
      oops: "Oops!",
      up: "Join Team",
      maxPlayer: "This team can only have up to {{count}} player(s)",
      mustChooseARole: "Characters with * are required characters",
      mustChooseARoleHint: "* are required characters",
      name: "Player’s Name",
      character: "Character",
      myself: "Me",
      reset: "Disband team",
      confirm: "Complete team",
      team: "Team",
      chooseRole: "Choose a role",
      clickRole: "Click a role",
      currentRole: "Current role",
      roleChosen: "has been chosen",
      leave: "Leave the team",
      confirming: "Wait for the leader to complete…",
      currentTeamSize:
        "There are {{count}} player(s) in the team now, is everyone here? (No more players can be added after the game starts.)",
      minTeamSize:
        "Oops! Your team does not have enough members! At least {{count}} players are required",
      maxTeamSize:
        "Oops! Your team have too many members! {{count}} players are allowed",
      roleDuplicatable:
        "Oops! You have chosen a duplicate role! This game does not accept duplicate roles",
      missingRole:
        "Oops! The required roles have not been chosen! Please make sure each required role is assigned to at least one player.",
      notSufficient: "Team creation conditions not met",
      letScan:
        "Please have other participants scan the QR code on the right to join this team",
      letScan2:
        "Please have other participants scan the QR code on the right to join this team",
      leader: "Team Leader",
      member: "Team Member",
      resetTeamConfirm: "Are you sure you want to disband the team?",
      exitTeamConfirm: "Are you sure you want to leave the team?",
      ready: "Ready",
      waiting: "Waiting for the team leader to start the experience...",
      start: "Start Experience",
      noTeamName: "My Team",
      serial: "Team Serial",
      player: "Player",
      noNamePlayer: "Unnamed Player",
      allHere: "Is everyone here?",
      confirmJoin:
        "Please confirm that all teammates have joined the team, with a total of {{count}} players (including you).",
      addTeammatesInstruction:
        "If you want to add more teammates later, press 'Settings (top right gear) > Add Teammates' during the experience",
      wait: "Wait...",
      startNew: "Let's Start!",
      displayName: "Display Name",
      editName: {
        part1: "Edit ",
        part2: " Name",
      },
      edit: {
        display: "Display",
        team: "Team",
      },
    },
  };
};
export default en;
