import React, { Suspense } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import _, { debounce } from "lodash";
import Loginwarn from "../../../assets/images/loginwarn.png";
import Nav from "./components/common/Nav";
import { isSafari } from "react-device-detect";
import { useTranslation } from "react-i18next";

const LoginPage = (props) => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const [state, setState] = React.useState({
    email: "",
    password: "",
  });
  const [width, setWidth] = React.useState(0);
  const [showWarning, setShowWarning] = React.useState(false);

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  React.useEffect(() => {
    if (!(getCookie("clubon_login_warning") === "false") && isSafari) {
      setShowWarning(true);
    }
  }, []);

  React.useEffect(() => {
    const handleResize = debounce(() => setWidth(window.innerWidth), 100);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  function setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }

  function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  const hideWarning = (e) => {
    e.preventDefault();
    let check = document.getElementById("dontshow");
    if (check.checked) {
      localStorage.setItem("clubon_login_warning", false);
      setCookie("clubon_login_warning", "false", 90);
    }
    setShowWarning(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post("/api/users/sign_in", {
        email: state.email,
        password: state.password,
      })
      .then((res) => {
        if (res.data.successful) {
          window.location.replace("/" + search);
        } else {
          let message = document.getElementById("message");
          message.innerText = res.data.message;
          setTimeout(() => (message.innerText = ""), 2000);
        }
      })
      .catch((err) => {
        let message = document.getElementById("message");
        message.innerText = t("login.error");
        setTimeout(() => (message.innerText = ""), 2000);
      });
  };

  return (
    <Suspense fallback="loading">
      <div style={{ height: "100%" }}>
        <Nav smallDevice={false} />
        {showWarning ? (
          <div
            className="w3-container"
            style={{
              height: "100%",
              padding: "100px 16px",
              fontFamily: "noto sans tc,Arial,sans-serif",
              fontWeight: "600",
              backgroundImage: `url(${Loginwarn})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
              backgroundPosition: "50% bottom",
              position: "relative",
            }}
            id="contact"
          >
            <div
              style={{
                position: "absolute",
                display: "flex",
                flexDirection: "column",
                bottom: "0",
                left: "50px",
                width: "60%",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <input
                  type="checkbox"
                  style={{ marginRight: "20px", transform: "scale(1.5)" }}
                  id="dontshow"
                  name="dontshow"
                />
                {t("login.dontShow")}
              </div>
              <div>
                <button
                  className="w3-button w3-black"
                  type="submit"
                  style={{ width: "100%" }}
                  id="button"
                  onClick={(e) => {
                    hideWarning(e);
                  }}
                >
                  <i className="fa-solid fa-angle-right"></i>{" "}
                  {t("login.continue")}
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div
            className="w3-container w3-light-grey"
            style={{
              height: "100%",
              padding: "100px 16px",
              fontFamily: "noto sans tc,Arial,sans-serif",
              fontWeight: "600",
            }}
            id="contact"
          >
            <p className="w3-center w3-large"></p>
            <div style={{ marginTop: "48px" }}>
              <div className="w3-col l12">
                <div className="w3-col l4">&nbsp;</div>
                <div className="w3-col l4">
                  <h3
                    className="w3-center"
                    style={{ fontWeight: "700", fontSize: "24px" }}
                  >
                    {t("login.login3")}
                  </h3>
                  <form action="/api/users/sign_in" method="POST">
                    <p style={{ margin: "20px 3vw" }}>
                      <input
                        style={{ borderRadius: "10px" }}
                        className="w3-input w3-border m-radius"
                        type="email"
                        placeholder={t("login.email")}
                        required
                        name="email"
                        value={state.email}
                        onChange={handleChange}
                      />
                    </p>
                    <p style={{ margin: "20px 3vw" }}>
                      <input
                        style={{ borderRadius: "10px" }}
                        className="w3-input w3-border m-radius"
                        type="password"
                        placeholder={t("inventory.enterPassword")}
                        required
                        name="password"
                        value={state.password}
                        onChange={handleChange}
                      />
                    </p>
                    <p
                      id="message"
                      style={{
                        color: "#DB485B",
                        minHeight: "0px",
                        margin: "0 3vw",
                      }}
                    ></p>
                    <p style={{ margin: "20px 3vw" }}>
                      <button
                        className="w3-button w3-black"
                        type="submit"
                        style={{ width: "100%" }}
                        id="button"
                        onClick={(e) => handleSubmit(e)}
                      >
                        <i className="fa-solid fa-angle-right"></i>{" "}
                        {t("login.login")}
                      </button>
                    </p>
                  </form>
                  <p className="w3-center" style={{ fontSize: "15px" }}>
                    <a className="w3-center-a" href="/forgot">
                      {" "}
                      {t("login.forgetPassword")} ？
                    </a>
                  </p>
                  <h4
                    className="w3-center"
                    style={{
                      marginTop: "100px",
                      fontSize: "20px",
                      fontWeight: "700",
                    }}
                  >
                    <a className="w3-center-a" href={"/signup" + search}>
                      {" "}
                      {t("login.goRegister")} →
                    </a>
                  </h4>
                </div>
                <div className="w3-col l4"></div>
              </div>
            </div>
          </div>
        )}
      </div>
    </Suspense>
  );
};

export default LoginPage;
