import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import zh from "./zh";
import en from "./en";

const resources = {
  zh: {
    translation: zh(),
  },
  en: {
    translation: en(),
  },
};

export const initI18n = () => {
  i18n.use(initReactI18next).init({
    resources,
    lng: "zh",
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    },
  });
};

export const changeLanguage = async (language) => {
  let callLanguage = language;
  if (callLanguage == "" || callLanguage == null || callLanguage == "null") {
    callLanguage = "zh";
  }
  if (i18n.language === callLanguage) {
    return; // No need to change language, exit function
  }
  i18n.changeLanguage(callLanguage);
};

export default i18n;
